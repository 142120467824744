

 import {  createStore  } from 'vuex'
 import createPersistedState from 'vuex-persistedstate'

 const vuex = createStore({
     plugins: [createPersistedState()],
     state: {
        training: null,
     },
     mutations: {
        save_training(state, tranning) {
            state.training = tranning;
        }
     },
     actions: {
        saveTrainning({ commit }, d) {
            commit('save_training', d)
        }
     },
     getters: {
        training: state => state.training
     }
 })

 export default vuex
