import { createWebHistory, createRouter } from 'vue-router';
import pathLoader from '../plugins/loader'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: pathLoader('home'),
    },
    {
      path: '/inscription',
      name: 'Inscription',
      component: pathLoader('inscription'),
  },
    {
        path: '/details',
        name: 'Details',
        component: pathLoader('details')
    },
    {
      path: '/about',
      name: 'About',
      component: pathLoader('about')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: pathLoader('blog')
}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      },
    
});

export default router;
