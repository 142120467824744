import { createApp } from 'vue'
import App from './App.vue'
import rooter from '../src/router/index'
import install from './install'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import './registerServiceWorker'
import i18n from './i18n'
import vuex from "./store";
import VueWriter from "vue-writer";
import './index.scss'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';



createApp(App)
    .use(i18n)
    .use(vuex)
    .use(VueSvgIconPlugin, { tagName: 'icon' })
    .use(VueWriter)
    .use(VueSweetalert2)
    .use(install)
    .use(rooter)
    .mount('#app')
