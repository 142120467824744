<template>
    <notifications position="top center" classes="notifications"  />
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
@import '/src/assets/styles/sass/variables';
#app {
  font-family: $font-default;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


</style>
